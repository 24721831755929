@media (min-width: 1600px) {
    .rainbow-auth-firebase-forgot-password_back-button {
        left: 120px !important;
    }
}

@media (max-width: 620px) {
    .rainbow-auth-firebase-forgot-password_container {
        height: 100%;
        padding-top: 116px;
    }
    .rainbow-auth-firebase-forgot-password_card {
        width: 100%;
        border-radius: 0;
        padding: 20px;
        -webkit-flex-direction: column;
        flex-direction: column;
    }
    .rainbow-auth-firebase-forgot-password_content {
        border-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .rainbow-auth-firebase-forgot-password_header {
        font-size: 20px;
        margin: 8px 0 12px 0;
    }
    .rainbow-auth-firebase-forgot-password_image {
        height: 32px;
        width: 32px;
    }
}
